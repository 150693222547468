import {Injectable} from '@angular/core';

import {
  CountAlertsResponse,
  GetAlertContextDataRequest,
  GetAlertContextDataResponse,
  ListAlertsRequest,
  ListAlertsForDashboardRequest,
  ListAlertsResponse,
} from '../jspb/alert_api_pb';
import {
  Alert,
  AlertConfig,
  Asset,
  BatteryCondition,
  Condition,
  GeofenceCondition,
  MovementCondition,
  Place,
  Trip,
  TripIdentifier,
} from '../jspb/entity_pb';
import {
  CurrentDeviceState,
  CurrentTemperature,
  ListDeviceDataRequest,
  ListDeviceDataResponse,
  ListDeviceStatesResponse,
  ListMeasuresRequest,
  ListMeasuresResponse,
} from '../jspb/metrics_api_pb';
import {MetricsClient} from '../jspb/Metrics_apiServiceClientPb';
import {
  CountDevicesResponse,
  GetMapsPeopleApiKeyRequest,
  GetMapsPeopleApiKeyResponse,
  GetUserByEmailRequest,
  GetUserByEmailResponse,
  ListDevicesRequest,
  ListOrganizationsRequest,
  ListOrganizationsResponse,
  ListUsersRequest,
  ListUsersResponse,
} from '../jspb/org_api_pb';
import {OrganizationsClient} from '../jspb/Org_apiServiceClientPb';

import {AuthService} from './auth-service';
import {
  catchError,
  first,
  map,
  shareReplay,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import {bindNodeCallback, Observable, of, throwError} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GenericErrorSnackbarComponent} from '../generic-error-snackbar/generic-error-snackbar.component';
import {AlertsClient} from '../jspb/Alert_apiServiceClientPb';
import {
  CountRequest,
  ListRequest,
} from '../all-entities-view/all-entities-view.component';
import {
  CountDevicesRequestInternal,
  ListDevicesRequestInternal,
} from '../devices-view/devices-view.component';
import {TripsClient} from '../jspb/Trip_apiServiceClientPb';
import {
  CountTripsResponse,
  GetEtaRequest,
  GetEtaResponse,
  GetTripRequest,
  ListAssetsRequest,
  ListAssetsResponse,
  ListTripsRequest,
  ListTripsResponse,
} from '../jspb/trip_api_pb';
import {Timestamp} from '../jspb/google/protobuf/timestamp_pb';
import {environment} from 'src/environments/environment';
import * as moment from 'moment';
import {BestLocation, Measures} from '../jspb/device_payload_pb';
import {Battery, PointLocation, Temperature} from '../jspb/sensors_pb';
import {LatLng, TimeRange} from '../jspb/common_pb';
import {BoolValue} from '../jspb/google/protobuf/wrappers_pb';
import {Field, Flow, TextField} from '../jspb/flow_pb';
import {
  FieldValue,
  FlowSubmission,
  GetDevicePairingReadinessRequest,
  GetDevicePairingReadinessResponse,
  GetTripPairingReadinessRequest,
  GetTripPairingReadinessResponse,
  ListFlowsRequest,
  ListFlowsResponse,
  LogInWithPairingCodeRequest,
  LogInWithPairingCodeResponse,
  SubmitFlowRequest,
  SubmitFlowResponse,
} from '../jspb/flow_api_pb';
import {
  FlowsClient,
  FlowSubmissionsClient,
  PairingAuthenticationClient,
} from '../jspb/Flow_apiServiceClientPb';
import {QueryParamService} from './query-param-service';
import AlertMetricData = GetAlertContextDataResponse.AlertMetricData;
import MetricType = ListMeasuresRequest.MetricType;
import {
  CountTripsRequestInternal,
  ListTripsRequestInternal,
} from '../trips-view/trips-view.component';

const CHART_SAMPLE_SIZE = 10000;
const ALERT_CONTEXT_DATA_SAMPLE_SIZE = 1000;
const ORG_IMPERSONATION_QUERY_PARAM = 'org';
const ORG_IMPERSONATION_HEADER = 'chorus-view-as-org';

interface BaseAssetWithLocation {
  asset: Asset;
  // Device the asset is paired to/tracked with.
  currentDeviceState: CurrentDeviceState;
}

export interface AssetWithPredictedLocation extends BaseAssetWithLocation {
  predictedForTimeSec: number;
  buildingId?: string;
  zoneName?: string;
  zoneCenterLat?: number;
  zoneCenterLng?: number;
  floorName?: string;
  zoneId?: string;
}

export interface AssetWithAbsoluteLocation extends BaseAssetWithLocation {
  location: PointLocation;
}

export type AssetWithLocation =
  | AssetWithPredictedLocation
  | AssetWithAbsoluteLocation;

@Injectable({providedIn: 'root'})
export class EndpointsService {
  alertsClient: AlertsClient;
  flowsClient: FlowsClient;
  orgsClient: OrganizationsClient;
  metricsClient: MetricsClient;
  pairingAuthenticationClient: PairingAuthenticationClient;
  flowSubmissionClient: FlowSubmissionsClient;
  tripsClient: TripsClient;

  constructor(
    private authService: AuthService,
    private queryParamService: QueryParamService,
    private snackBar: MatSnackBar
  ) {
    this.alertsClient = new AlertsClient(environment.apiUrl);
    this.flowsClient = new FlowsClient(environment.apiUrl);
    this.orgsClient = new OrganizationsClient(environment.apiUrl);
    this.metricsClient = new MetricsClient(environment.apiUrl);
    this.pairingAuthenticationClient = new PairingAuthenticationClient(
      environment.apiUrl
    );
    this.flowSubmissionClient = new FlowSubmissionsClient(environment.apiUrl);
    this.tripsClient = new TripsClient(environment.apiUrl);
  }

  submitFlow(
    flowId: string,
    values: FieldValue[]
  ): Observable<SubmitFlowResponse> {
    const request = new SubmitFlowRequest();
    const flow_submission = new FlowSubmission();
    flow_submission.setFlowId(flowId);
    flow_submission.setValuesList(values);
    request.setFlowSubmission(flow_submission);
    return this.grpcToObservable<SubmitFlowRequest, SubmitFlowResponse>(
      this.flowSubmissionClient.submitFlow.bind(this.flowSubmissionClient),
      request,
      /* showGenericErrorOnFailure= */ false
    );
  }

  getDevicePairingReadiness(
    deviceId: string
  ): Observable<GetDevicePairingReadinessResponse> {
    const request = new GetDevicePairingReadinessRequest();
    request.setDeviceId(deviceId);
    return this.grpcToObservable<
      GetDevicePairingReadinessRequest,
      GetDevicePairingReadinessResponse
    >(
      this.flowSubmissionClient.getDevicePairingReadiness.bind(
        this.flowSubmissionClient
      ),
      request,
      /* showGenericErrorOnFailure= */ false
    );
  }

  getTripPairingReadiness(
    tripCustomerId: string
  ): Observable<GetTripPairingReadinessResponse> {
    const request = new GetTripPairingReadinessRequest();
    request.setTripCustomerId(tripCustomerId);
    return this.grpcToObservable<
      GetTripPairingReadinessRequest,
      GetTripPairingReadinessResponse
    >(
      this.flowSubmissionClient.getTripPairingReadiness.bind(
        this.flowSubmissionClient
      ),
      request,
      /* showGenericErrorOnFailure= */ false
    );
  }

  logInWithPairingCode(
    pairingCode: string
  ): Observable<LogInWithPairingCodeResponse> {
    const request = new LogInWithPairingCodeRequest();
    request.setPairingCode(pairingCode);
    return this.grpcToObservable<
      LogInWithPairingCodeRequest,
      LogInWithPairingCodeResponse
    >(
      this.pairingAuthenticationClient.logInWithPairingCode.bind(
        this.pairingAuthenticationClient
      ),
      request,
      false
    );
  }

  listFlows(
    pageSize: number,
    pageToken?: string
  ): Observable<ListFlowsResponse> {
    const request = new ListFlowsRequest();
    request.setPageSize(pageSize);
    request.setPageToken(pageToken);
    return this.grpcToObservable<ListFlowsRequest, ListFlowsResponse>(
      this.flowsClient.listFlows.bind(this.flowsClient),
      request
    );
  }

  listAssets(
    searchString: string,
    pageSize: number
  ): Observable<ListAssetsResponse> {
    const request = new ListAssetsRequest();
    request.setSearchString(searchString);
    request.setPageSize(pageSize);
    return this.grpcToObservable<ListAssetsRequest, ListAssetsResponse>(
      this.tripsClient.listAssets.bind(this.tripsClient),
      request
    );
  }

  getTripEta(chorusId: string): Observable<Timestamp> {
    const request = new GetEtaRequest();
    const tripIdentifier = new TripIdentifier();
    tripIdentifier.setScoutId(chorusId);
    request.setTripIdentifier(tripIdentifier);
    // Don't show an error on failure since there isn't always an ETA available
    // for a trip.
    return this.grpcToObservable<GetEtaRequest, GetEtaResponse>(
      this.tripsClient.getEta.bind(this.tripsClient),
      request,
      /* showGenericErrorOnFailure= */ false
    ).pipe(
      map((response) => response.getEta()),
      catchError(() => of(null))
    );
  }

  getTrip(scoutId: string): Observable<Trip> {
    const request = new GetTripRequest();
    const tripIdentifier = new TripIdentifier();
    tripIdentifier.setScoutId(scoutId);
    request.setTripIdentifier(tripIdentifier);
    request.setReturnDevices(true);
    request.setReturnAssets(true);
    return this.grpcToObservable<GetTripRequest, Trip>(
      this.tripsClient.getTrip.bind(this.tripsClient),
      request
    );
  }

  listTrips({
    pageSize,
    pageToken,
    searchString,
    tripStages,
  }: ListTripsRequestInternal): Observable<ListTripsResponse> {
    const request = new ListTripsRequest();
    request.setPageSize(pageSize);
    if (pageToken) {
      request.setPageToken(pageToken);
    }
    request.setSearchString(searchString);
    if (tripStages) {
      request.setTripStagesList(tripStages);
    }
    request.setReturnDevices(true);
    request.setReturnAssets(true);
    return this.grpcToObservable<ListTripsRequest, ListTripsResponse>(
      this.tripsClient.listTrips.bind(this.tripsClient),
      request
    );
  }

  countTrips({
    searchString,
    tripStages,
  }: CountTripsRequestInternal): Observable<CountTripsResponse> {
    const request = new ListTripsRequest();
    request.setSearchString(searchString);
    if (tripStages) {
      request.setTripStagesList(tripStages);
    }
    return this.grpcToObservable<ListTripsRequest, CountTripsResponse>(
      this.tripsClient.countTrips.bind(this.tripsClient),
      request
    );
  }

  // TODO(patkbriggs) Replace with real data :)
  // TODO(patkbriggs) Translation
  async getDeviceTimeline(): Promise<any[]> {
    return Promise.resolve([
      {
        name: 'No movement alert',
        locationString: '11.258, -109.872',
        timeMs: 1578343161000,
        type: 'alert',
      },
      {
        name: 'In Transit',
        locationString: '32.501, 11.225',
        timeMs: 1578061501000,
        type: 'transit',
      },
      {
        name: 'Device Provisioned',
        timeMs: 1578061501000,
        type: 'provision',
      },
    ]);
  }

  // TODO(patkbriggs) Replace with real data :)
  // TODO(patkbriggs) Translation
  async getTripTimeline(): Promise<any[]> {
    return Promise.resolve([
      {
        name: 'No movement alert',
        locationString: '11.258, -109.872',
        timeMs: 1578343161000,
        type: 'alert',
      },
      {
        name: 'In Transit',
        locationString: '32.501, 11.225',
        timeMs: 1578061501000,
        type: 'transit',
      },
      {
        name: 'Trip created',
        timeMs: 1578061501000,
        type: 'create_trip',
      },
    ]);
  }

  // TODO(patkbriggs) Add real implementation and add proper return type.
  async getAlertMapData(): Promise<any[]> {
    return Promise.resolve([
      {
        lat: 40.466339,
        lng: -114.417872,
        type: 'Cold chain',
        start_time_s: 1576807164,
      },
      {
        lat: 31.654,
        lng: -97.69,
        type: 'Pressure',
        start_time_s: 1576721103,
      },
      {
        lat: 36.12,
        lng: -81.49,
        type: 'Non-movement',
        start_time_s: 1576417190,
      },
    ]);
  }

  // TODO(patkbriggs) Add real implementation and add proper return type.
  async listAlertOverviews(): Promise<any[]> {
    return Promise.resolve([
      {
        name: 'Total Alerts',
        count: 127,
        icon: 'warning',
      },
      {
        name: 'Unclaimed Alerts',
        count: 38,
        icon: 'warning',
      },
      {
        name: 'Temperature Alerts',
        count: 4,
        icon: 'ac_unit',
      },
    ]);
  }

  // TODO(patkbriggs) Remove once real implementation is added.
  private createAlert(
    configName: string,
    deviceId: string,
    startTimeMs?: number,
    endTimeMs?: number,
    id?: string
  ): Alert {
    const alert = new Alert();
    alert.setAlertId(id);
    const alertConfig = new AlertConfig();
    alertConfig.setName(configName);
    alert.setAlertConfig(alertConfig);
    alert.setDeviceId(deviceId);
    const asset = new Asset();
    asset.setCustomerId('KrabbyPattySecretFormula01');
    alert.setAssetsList([asset]);
    const startTimestamp = new Timestamp();
    startTimestamp.setSeconds(startTimeMs / 1e3 || new Date().getTime() / 1e3);
    alert.setIncidentTime(startTimestamp);
    if (endTimeMs) {
      const endTimestamp = new Timestamp();
      endTimestamp.setSeconds(startTimeMs / 1e3);
      alert.setResolutionTime(endTimestamp);
    }
    return alert;
  }

  // ALERTS CLIENT

  getAlert(scoutId: string): Observable<Alert> {
    // TODO(patkbriggs) Replace with getAlertContextData at calling site
    return this.getAlertContextData(scoutId).pipe(
      map((response) => response.getAlert())
    );
  }

  listAlerts({
    pageSize,
    pageToken,
    searchString,
  }: ListRequest): Observable<ListAlertsResponse> {
    const request = new ListAlertsForDashboardRequest();
    request.setPageSize(pageSize);
    if (pageToken) {
      request.setPageToken(pageToken);
    }
    return this.grpcToObservable<
      ListAlertsForDashboardRequest,
      ListAlertsResponse
    >(
      this.alertsClient.listAlertsForDashboard.bind(this.alertsClient),
      request
    );
  }

  countAlerts(_: CountRequest): Observable<CountAlertsResponse> {
    const request = new ListAlertsRequest();
    return this.grpcToObservable<ListAlertsRequest, CountAlertsResponse>(
      this.alertsClient.countAlerts.bind(this.alertsClient),
      request
    );
  }

  getAlertContextData(
    alertId: string,
    pageToken?: string
  ): Observable<GetAlertContextDataResponse> {
    const request = new GetAlertContextDataRequest();
    request.setAlertId(alertId);
    request.setPageToken(pageToken);
    request.setPageSize(ALERT_CONTEXT_DATA_SAMPLE_SIZE);
    return this.grpcToObservable<
      GetAlertContextDataRequest,
      GetAlertContextDataResponse
    >(
      this.alertsClient.getAlertContextData.bind(this.alertsClient),
      request
    ).pipe(
      map((response) => {
        if (
          response.getAlert().getAlertId() !==
          '49f17e7a-77a9-4c7a-9a4c-8f4df2fd1ab7'
        ) {
          return response;
        }
        // TODO(patkbriggs) Remove after demo
        const locationData = new AlertMetricData();
        locationData.setMetricType(MetricType.BATTERY);
        locationData.setMeasuresList([
          createBatteryMeasure(moment().subtract(5, 'hours'), 40),
          createBatteryMeasure(moment().subtract(4, 'hours'), 32),
          createBatteryMeasure(moment().subtract(3, 'hours'), 23),
          createBatteryMeasure(moment().subtract(2, 'hours'), 17),
          createBatteryMeasure(moment().subtract(1, 'hours'), 26),
          createBatteryMeasure(moment(), 39),
        ]);
        const batteryData = new AlertMetricData();
        batteryData.setMetricType(MetricType.LOCATION);
        batteryData.setMeasuresList([
          createLocationMeasure(
            moment().subtract(5, 'hours'),
            30.404011,
            -97.727052
          ),
          createLocationMeasure(
            moment().subtract(4, 'hours'),
            30.379486,
            -97.73806
          ),
          createLocationMeasure(
            moment().subtract(3, 'hours'),
            30.337011,
            -97.755569
          ),
          createLocationMeasure(
            moment().subtract(2, 'hours'),
            30.285959,
            -97.76398
          ),
          createLocationMeasure(
            moment().subtract(1, 'hours'),
            30.265574,
            -97.783636
          ),
          createLocationMeasure(moment(), 30.23918, -97.818998),
        ]);

        const movementData = new AlertMetricData();
        movementData.setMetricType(MetricType.STARTED_STOPPED_MOVING);
        movementData.setMeasuresList([
          createMovementMeasure(moment().subtract(5, 'hours'), false),
          createMovementMeasure(moment().subtract(4, 'hours'), false),
          createMovementMeasure(moment().subtract(3, 'hours'), true),
          createMovementMeasure(moment().subtract(2, 'hours'), true),
          createMovementMeasure(moment().subtract(1, 'hours'), false),
          createMovementMeasure(moment(), false),
        ]);
        response.setMetricDataList([locationData, batteryData, movementData]);

        response
          .getAlert()
          .getIncidentTime()
          .setSeconds(
            moment().subtract(3, 'hours').subtract(2, 'minutes').unix()
          );
        response
          .getAlert()
          .getResolutionTime()
          .setSeconds(moment().subtract(60, 'minutes').unix());
        const conditionForGeofence = new Condition();
        const geofenceCondition = new GeofenceCondition();
        geofenceCondition.setRadiusMeters(150);
        const place = new Place();
        const geoPointAndRadius = new Place.GeoPointAndRadius();
        const point = new LatLng();
        point.setLatitudeMicro(30297011);
        point.setLongitudeMicro(-97770569);
        geoPointAndRadius.setPoint(point);
        geoPointAndRadius.setRadiusMeters(5000);
        place.setPointAndRadius(geoPointAndRadius);
        geofenceCondition.setPlace(place);
        conditionForGeofence.setGeofence(geofenceCondition);
        const conditionForBattery = new Condition();
        const batteryCondition = new BatteryCondition();
        batteryCondition.setMinBatterySoc(25);
        conditionForBattery.setBattery(batteryCondition);
        const conditionForMovement = new Condition();
        const movementCondition = new MovementCondition();
        movementCondition.setType(
          MovementCondition.MovementConditionType.STOPPED
        );
        movementCondition.setDurationSec(3600);
        conditionForMovement.setMovement(movementCondition);
        response
          .getAlert()
          .getAlertConfig()
          .setConditionsList([
            conditionForGeofence,
            conditionForBattery,
            conditionForMovement,
          ]);
        response.getAlert().getAlertConfig().setName('Test Alert');
        return response;
      }),
      // TODO(patkbriggs) Remove once sorting is consistent
      map((response: GetAlertContextDataResponse) => {
        for (const metricData of response.getMetricDataList()) {
          metricData.setMeasuresList(
            metricData.getMeasuresList().sort(measuresTimeCompareFn)
          );
        }
        return response;
      })
    );
  }

  // ORGS CLIENT

  getMapsPeopleApiKey(): Observable<GetMapsPeopleApiKeyResponse> {
    return this.grpcToObservable<
      GetMapsPeopleApiKeyRequest,
      GetMapsPeopleApiKeyResponse
    >(
      this.orgsClient.getMapsPeopleApiKey.bind(this.orgsClient),
      new GetMapsPeopleApiKeyRequest(),
      /* showGenericErrorOnFailure= */ false
    );
  }

  countDevices(
    countRequest: CountDevicesRequestInternal
  ): Observable<CountDevicesResponse> {
    const request = new ListDevicesRequest();
    request.setSearchString(countRequest.searchString);
    request.setOnTrip(countRequest.onTrip);
    return this.grpcToObservable<ListDevicesRequest, CountDevicesResponse>(
      this.orgsClient.countDevices.bind(this.orgsClient),
      request
    );
  }

  listOrganizations(
    pageSize: number,
    returnOwnOrg: boolean
  ): Observable<ListOrganizationsResponse> {
    const request = new ListOrganizationsRequest();
    request.setReturnOwnOrg(returnOwnOrg);
    request.setPageSize(pageSize);
    return this.grpcToObservable<
      ListOrganizationsRequest,
      ListOrganizationsResponse
    >(this.orgsClient.listOrganizations.bind(this.orgsClient), request);
  }

  listUsers() {
    return this.grpcToPromise<ListUsersRequest, ListUsersResponse>(
      this.orgsClient.listUsers.bind(this.orgsClient),
      new ListUsersRequest()
    );
  }

  getUserByEmail(email: string): Observable<GetUserByEmailResponse> {
    const request = new GetUserByEmailRequest();
    request.setEmail(email);
    // We don't show a generic error on failure because we expect to receive
    // a non-standard return code if the user is not associated with an org.
    return this.grpcToObservable<GetUserByEmailRequest, GetUserByEmailResponse>(
      this.orgsClient.getUserByEmail.bind(this.orgsClient),
      request,
      /* showGenericErrorOnFailure= */ false
    );
  }

  // METRICS CLIENT

  getDeviceState(deviceId: string): Observable<CurrentDeviceState | null> {
    const request = new ListDevicesRequest();
    request.setDeviceId(deviceId);
    request.setReturnTrips(true);
    request.setReturnAssets(true);
    request.setReturnOrgs(true);
    request.setReturnDebugInfo(true);
    return this.grpcToObservable<ListDevicesRequest, ListDeviceStatesResponse>(
      this.metricsClient.listDeviceStates.bind(this.metricsClient),
      request
    ).pipe(
      map((listDeviceStatesResponse) => {
        return listDeviceStatesResponse.getDeviceStatesList()[0] || null;
      })
    );
  }

  listDeviceStates({
    pageSize,
    pageToken,
    searchString,
    onTrip,
  }: ListDevicesRequestInternal): Observable<ListDeviceStatesResponse> {
    const request = new ListDevicesRequest();
    request.setPageSize(pageSize);
    request.setReturnTrips(true);
    request.setReturnAssets(true);
    request.setOnTrip(onTrip);
    if (pageToken) {
      request.setPageToken(pageToken);
    }
    request.setSearchString(searchString);
    return this.listDeviceStatesWithRequest(request);
  }

  listDeviceStatesForIds(
    deviceIds: string[],
    pageSize: number,
    pageToken?: string
  ): Observable<ListDeviceStatesResponse> {
    const request = new ListDevicesRequest();
    request.setPageSize(pageSize);
    if (pageToken) {
      request.setPageToken(pageToken);
    }
    request.setDeviceIdsList(deviceIds);
    return this.listDeviceStatesWithRequest(request);
  }

  listDeviceStatesWithActiveAssets({
    pageSize,
    pageToken,
    searchString,
  }: ListRequest): Observable<ListDeviceStatesResponse> {
    const request = new ListDevicesRequest();
    request.setPageSize(pageSize);
    request.setReturnAssets(true);
    request.setWithAsset(true);
    if (pageToken) {
      request.setPageToken(pageToken);
    }
    request.setSearchString(searchString);
    return this.listDeviceStatesWithRequest(request);
  }

  private listDeviceStatesWithRequest(
    request: ListDevicesRequest
  ): Observable<ListDeviceStatesResponse> {
    return this.grpcToObservable<ListDevicesRequest, ListDeviceStatesResponse>(
      this.metricsClient.listDeviceStates.bind(this.metricsClient),
      request
    );
  }

  listDeviceData(
    deviceId: string,
    timeWindowStartTimeSec: number,
    timeWindowEndTimeSec: number,
    lastReceivedTimestampSecForMetricType?: number
  ): Observable<ListDeviceDataResponse> {
    const request = new ListDeviceDataRequest();
    request.addDeviceIds(deviceId);
    request.setPageSize(CHART_SAMPLE_SIZE);
    if (lastReceivedTimestampSecForMetricType) {
      // Add 1 because the time is inclusive.
      request.setMinRecordTime(
        getTimestampForSeconds(lastReceivedTimestampSecForMetricType + 1)
      );
    } else {
      request.setMinRecordTime(getTimestampForSeconds(timeWindowStartTimeSec));
    }
    request.setMaxRecordTime(getTimestampForSeconds(timeWindowEndTimeSec));
    return this.grpcToObservable<ListDeviceDataRequest, ListDeviceDataResponse>(
      this.metricsClient.listDeviceData.bind(this.metricsClient),
      request
    ).pipe(
      // TODO(patkbriggs) Remove once sorting is consistent
      map((response: ListDeviceDataResponse) => {
        response.setMeasuresList(
          response.getMeasuresList().sort(measuresTimeCompareFn)
        );
        return response;
      })
    );
  }

  /**
   * @param deviceIds
   * @param metricType
   * @param timeWindowStartTimeSec
   * @param timeWindowEndTimeSec
   * @param lastReceivedTimestampSecForMetricType If specified, this returns
   *    only data points collected after the given time. Overrides
   *    timeWindowStartTimeSec.
   */
  listMeasures(
    deviceId: string,
    metricType: ListMeasuresRequest.MetricType,
    timeWindowStartTimeSec: number,
    timeWindowEndTimeSec: number,
    lastReceivedTimestampSecForMetricType?: number
  ): Observable<ListMeasuresResponse> {
    const request = new ListMeasuresRequest();
    request.addDeviceId(deviceId);
    request.setApproxSampleSize(CHART_SAMPLE_SIZE);
    if (lastReceivedTimestampSecForMetricType) {
      // Add 1 because the time is inclusive.
      request.setMeasuredAfterTimestampSeconds(
        lastReceivedTimestampSecForMetricType + 1
      );
    } else {
      request.setMeasuredAfterTimestampSeconds(timeWindowStartTimeSec);
    }
    request.setMeasuredBeforeTimestampSeconds(timeWindowEndTimeSec);
    request.setMetricType(metricType);
    return this.grpcToObservable<ListMeasuresRequest, ListMeasuresResponse>(
      this.metricsClient.listMeasures.bind(this.metricsClient),
      request
    ).pipe(
      // TODO(patkbriggs) Remove once sorting is consistent
      map((response: ListMeasuresResponse) => {
        response.setMeasuresList(
          response.getMeasuresList().sort(measuresTimeCompareFn)
        );
        return response;
      })
    );
  }

  private showGenericError() {
    this.snackBar.openFromComponent(GenericErrorSnackbarComponent);
  }

  /** @deprecated in favor of {@link grpcToObservable}  */
  async grpcToPromise<Req, Res>(
    grpcClientMethod: Function,
    req?: Req
  ): Promise<Res> {
    const idToken = await this.authService.userToken$.pipe(first()).toPromise();
    return new Promise<Res>((resolve, reject) => {
      const requestMetadata = {Authorization: `Bearer ${idToken}`};
      grpcClientMethod(req, requestMetadata, (err, res: Res) => {
        return err ? reject(err) : resolve(res);
      });
    });
  }

  private grpcToObservable<RequestProto, ResponseProto>(
    grpcClientMethod: Function,
    request: RequestProto,
    showGenericErrorOnFailure: boolean = true
  ): Observable<ResponseProto> {
    return this.authService.userToken$.pipe(
      // We're only making a single RPC; thus, we only take a single user
      // token so the returned Observable completes when the RPC finishes.
      take(1),
      withLatestFrom(
        this.queryParamService.getParam(ORG_IMPERSONATION_QUERY_PARAM)
      ),
      switchMap(
        ([userToken, impersonatedOrgName]): Observable<ResponseProto> => {
          // The response must first be cast as unknown because bindNodeCallback
          // can't guarantee the return type of the callback function without
          // crazy templating.
          return bindNodeCallback(grpcClientMethod)(
            request,
            getGrpcRequestMetadata(userToken, impersonatedOrgName)
          ) as unknown as Observable<ResponseProto>;
        }
      ),
      catchError((error: any) => {
        if (showGenericErrorOnFailure) {
          this.showGenericError();
        }
        // Rethrow the error so the caller can catch it and, for example,
        // update their loading state.
        return throwError(error);
      }),
      shareReplay({refCount: true, bufferSize: 1})
    );
  }
}

function getGrpcRequestMetadata(
  userToken: string | null,
  impersonatedOrgName: string | null
): Object | null {
  if (userToken == null && impersonatedOrgName == null) {
    return null;
  }
  const metadata = {};
  if (userToken) {
    metadata['Authorization'] = `Bearer ${userToken}`;
  }
  if (impersonatedOrgName) {
    metadata[ORG_IMPERSONATION_HEADER] = impersonatedOrgName;
  }
  return metadata;
}

function getTimestampForSeconds(seconds: number): Timestamp {
  const timestamp = new Timestamp();
  timestamp.setSeconds(seconds);
  return timestamp;
}

function createTemperatureMeasure(
  time: moment.Moment,
  temperatureCelsius: number
): Measures {
  const measure = new Measures();
  const recordTimestamp = new Timestamp();
  recordTimestamp.setSeconds(time.unix());
  measure.setRecordTime(recordTimestamp);
  const temperature = new Temperature();
  temperature.setTemperatureCelsiusMilli(temperatureCelsius * 1e3);
  measure.setTemperature(temperature);
  return measure;
}

function createLocationMeasure(
  time: moment.Moment,
  lat: number,
  lng: number
): Measures {
  const measure = new Measures();
  const recordTimestamp = new Timestamp();
  recordTimestamp.setSeconds(time.unix());
  measure.setRecordTime(recordTimestamp);
  const pointLocation = new PointLocation();
  const latLng = new LatLng();
  latLng.setLatitudeMicro(lat * 1e6);
  latLng.setLongitudeMicro(lng * 1e6);
  pointLocation.setLatlng(latLng);
  // WARNING: We're missing the location source ID here, but it doesn't really
  // matter since this is only used for a temporary demo.
  const bestLocation = new BestLocation();
  bestLocation.setLocation(pointLocation);
  bestLocation.setLocationTime(recordTimestamp);
  measure.setBestLocation(bestLocation);
  return measure;
}

function createBatteryMeasure(
  time: moment.Moment,
  batteryPercent: number
): Measures {
  const measure = new Measures();
  const recordTimestamp = new Timestamp();
  recordTimestamp.setSeconds(time.unix());
  measure.setRecordTime(recordTimestamp);
  const battery = new Battery();
  battery.setBatterySocPercent(batteryPercent);
  measure.setBattery(battery);
  return measure;
}

function createMovementMeasure(time: moment.Moment, moving: boolean): Measures {
  const measure = new Measures();
  const recordTimestamp = new Timestamp();
  recordTimestamp.setSeconds(time.unix());
  measure.setRecordTime(recordTimestamp);
  const value = new BoolValue();
  value.setValue(moving);
  measure.setStartedStoppedMoving(value);
  return measure;
}

function measuresTimeCompareFn(a: Measures, b: Measures): number {
  return a.getRecordTime().getSeconds() - b.getRecordTime().getSeconds();
}

function createFlow({
  name = 'Packout',
  fields = [],
}: {
  name?: string;
  fields?: Field[];
} = {}): Flow {
  const flow = new Flow();
  flow.setFlowId(name + new Date().getMilliseconds());
  flow.setFlowName(name);
  flow.setFieldsList(fields);
  return flow;
}

function createField({
  displayLabel = 'ID',
  minimumMultiplicity = 1,
}: {
  displayLabel?: string;
  minimumMultiplicity?: number;
} = {}): Field {
  const field = new Field();
  field.setFieldId(Math.floor(Math.random() * 10000).toString());
  field.setDisplayLabel(displayLabel);
  field.setMinimumMultiplicity(minimumMultiplicity);
  return field;
}

function createTextField({
  displayLabel = 'ID',
  minimumMultiplicity = 1,
}: {
  displayLabel?: string;
  minimumMultiplicity?: number;
} = {}): Field {
  const field = createField({displayLabel, minimumMultiplicity});
  field.setTextField(new TextField());
  return field;
}

function createAssetWithAssetId(assetId: string): Asset {
  const asset = new Asset();
  asset.setAssetId(assetId);
  return asset;
}

function createCurrentDeviceState({
  lastCheckInTimeMs,
  deviceId,
  temperatureCelsius,
}: {
  lastCheckInTimeMs?: number;
  deviceId?: string;
  temperatureCelsius?: number;
}): CurrentDeviceState {
  const currentDeviceState = new CurrentDeviceState();
  if (lastCheckInTimeMs != undefined) {
    currentDeviceState.setLastCheckInTimestampMillis(lastCheckInTimeMs);
  }
  if (deviceId != undefined) {
    currentDeviceState.setDeviceId(deviceId);
  }
  if (temperatureCelsius != undefined) {
    const currentTemperature = new CurrentTemperature();
    const temperature = new Temperature();
    temperature.setTemperatureCelsiusMilli(temperatureCelsius * 1e3);
    currentTemperature.setTemperature(temperature);
    currentDeviceState.setCurrentTemperature(currentTemperature);
  }
  return currentDeviceState;
}
