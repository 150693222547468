<div class="text-field-container">
  <current-field-indicator
    [currentField]="showCurrentFieldIndicator"
  ></current-field-indicator>
  <div
    #fieldContainer
    class="field-container"
    [class.current-field]="showCurrentFieldIndicator"
    (focusout)="maybeShowMultiplicityErrors($event)"
  >
    <label id="{{ labelHtmlId }}" class="field-name">
      {{ field.getDisplayLabel() }}
    </label>
    <div
      *ngFor="let control of fields.controls; index as i"
      class="field-and-error"
    >
      <ng-container
        *ngIf="
          !!control.value ||
            (pairModel.selectedInputMethod$ | async) === InputMethod.SCANNER;
          then inputTemplate;
          else buttonTemplate
        "
      >
      </ng-container>
      <mat-error class="field-error">
        <!-- Only show the error on empty fields to encourage them to be filled in. This is
         separated from the other errors because this error occurs when a value *hasn't
         been provided* (as opposed to a provided value being invalid) -->
        <ng-container
          *ngIf="
            fields.errors &&
              fields.errors.minMultiplicity &&
              !(control.value && control.value.trim()) &&
              showMultiplicityErrors$ | async
          "
          i18n="
            Error message shown when at least N values must be specified in a
            form. For example, if N=3 and the user only enters 2 values, this
            error would be shown
          "
        >
          At least { field.getMinimumMultiplicity(), plural, =1 {1 value} other
          {{{field.getMinimumMultiplicity()}} values}} must be provided
        </ng-container>
        <ng-container *ngIf="control.errors">
          <ng-container
            *ngIf="control.errors.deviceNotFound"
            i18n="
              Error message shown when the user tried to pair/associate a
              tracking device with a shipment but the entered tracker ID was not
              recognized by the system
            "
          >
            Device not found. Please check that the entered ID matches the
            barcode or try using another device
          </ng-container>
          <ng-container
            *ngIf="control.errors.pattern"
            i18n="
              Error message shown when a user enters a value that does not match
              the required pattern. For example, if the field corresponds to
              'Shipment ID' and all shipments need an ID starting with 'SHIP-',
              but the user enters an ID of '123', it would show 'Invalid
              Shipment ID'
            "
          >
            Invalid {{ field.getDisplayLabel() }}
          </ng-container>
          <ng-container
            *ngIf="control.errors.unknownError"
            i18n="
              Error message shown when the system tried to validate a value the
              user entered but there was an unknown issue
            "
          >
            Unknown error. Please try again or try a different value
          </ng-container>
        </ng-container>
      </mat-error>
      <div *ngIf="!control.error && i == mostRecentWarningInputIndex">
        <div
          class="field-warning"
          *ngIf="deviceAlreadyPairedWithTrip"
          i18n="
            Warning message shown when the user tries to pair/associate a device
            that has already been paired/associated with at least one unfinished
            trip/shipment
          "
        >
          This device has already been paired with at least one trip that has
          not ended.
        </div>
        <div class="field-message" *ngIf="lastCheckInDateTime">
          <mat-icon class="green-check" *ngIf="!deviceHasOldLastCheckIn"
            >done</mat-icon
          >
          <mat-icon class="orange-x" *ngIf="deviceHasOldLastCheckIn"
            >close</mat-icon
          >
          <span i18n="Last check-in time label">Last check-in:</span>
          <span
            class="field-value-ok"
            [class.field-value-warning]="deviceHasOldLastCheckIn"
            >{{ lastCheckInDateTime | amTimeAgo }}</span
          >
        </div>
        <div
          class="field-message"
          *ngIf="battery && battery.getBatterySocPercent()"
        >
          <mat-icon class="green-check" *ngIf="!deviceHasLowBattery"
            >done</mat-icon
          >
          <mat-icon class="orange-x" *ngIf="deviceHasLowBattery"
            >close</mat-icon
          >
          <span i18n="Battery label">Battery:</span>
          <span
            class="field-value-ok"
            [class.field-value-warning]="deviceHasLowBattery"
            i18n="
              Quantitative battery percentage formatted according to warning
              status
            "
            >{{ battery.getBatterySocPercent() }}%</span
          >
        </div>
        <div
          class="field-message"
          *ngIf="battery && !battery.getBatterySocPercent()"
        >
          <mat-icon class="green-check" *ngIf="!deviceHasLowBattery"
            >done</mat-icon
          >
          <mat-icon class="orange-x" *ngIf="deviceHasLowBattery"
            >close</mat-icon
          >
          <span i18n="Battery label">Battery:</span>
          <span
            *ngIf="!deviceHasLowBattery"
            class="field-value-ok"
            i18n="Qualitative sufficient battery status"
            >OK</span
          >
          <span
            *ngIf="deviceHasLowBattery"
            class="field-value-warning"
            i18n="Qualitative low or empty battery warning status"
            >Low or Empty</span
          >
        </div>
      </div>
      <!-- We specifically use keyup because we don't want the keydown event to be handled
       by this element, have focus progress to the submit button, then have it automatically
       submit due to the corresponding keyup event -->
      <ng-template #inputTemplate>
        <input
          #fieldInput
          matInput
          class="text-field-input field"
          autocapitalize="off"
          autocomplete="false"
          spellcheck="false"
          [class.has-errors]="control.errors"
          [class.has-warnings]="
            i == mostRecentWarningInputIndex &&
            (deviceAlreadyPairedWithTrip ||
              deviceHasOldLastCheckIn ||
              deviceHasLowBattery)
          "
          [attr.aria-labelledby]="labelHtmlId"
          [formControl]="control"
          (keyup.enter)="focusNextInput(i)"
          (focus)="updateFocusedInputIndex(i)"
        />
        <button
          *ngIf="
            control.value &&
            (pairModel.selectedInputMethod$ | async) !== InputMethod.CAMERA
          "
          mat-icon-button
          class="clear-button"
          (click)="clearInput(i)"
        >
          <mat-icon>close</mat-icon>
        </button>
        <button
          *ngIf="
            control.value &&
            (pairModel.selectedInputMethod$ | async) === InputMethod.CAMERA
          "
          mat-icon-button
          class="retake-button"
          (click)="retakeInputValue(i)"
        >
          <mat-icon>replay</mat-icon>
        </button>
      </ng-template>
      <ng-template #buttonTemplate>
        <button
          #fieldButton
          mat-stroked-button
          mat-button
          i18n="
            Label for a button that opens the device's camera and lets the user
            scan a barcode/QR code. The placeholder represents the 'type' of
            barcode. For example, one form field might ask for the 'Product ID'
            and another for the 'Shipping label'. In these examples, the entire
            string would be 'Scan Product ID' and 'Scan Shipping label'
          "
          class="scan-button field"
          (click)="scanForInput(i)"
        >
          Scan {{ field.getDisplayLabel() }}
        </button>
      </ng-template>
    </div>
  </div>
</div>
